// const EmptyCard: React.FC = () => (
//   <div className="bg-gray-100 rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl mx-2 my-4 p-6 flex items-center justify-center h-[500px]">
//     <p className="text-gray-400 text-xl font-sans">More reviews coming soon!</p>
//   </div>
// );

import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

interface Review {
  id: number;
  text: string;
  image: string;
  rating: number;
  name: string;
}

interface ReviewCarouselProps {
  reviews: Review[];
}

const ReviewCard: React.FC<Review> = ({ text, image, rating, name }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);
  const [shouldShowMore, setShouldShowMore] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      setShouldShowMore(contentRef.current.scrollHeight > 150);
    }
  }, [text]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl mx-2 my-4 p-6 flex flex-col ${isExpanded ? 'h-auto' : 'h-[500px]'}`}>
      <img className="object-cover w-full h-48 rounded-lg mb-4" src={image} alt={`Review`} />
      <div className="flex justify-center mb-3">
        {[...Array(5)].map((_, i) => (
          <span key={i} className={`text-3xl ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`}>★</span>
        ))}
      </div>
      <div className="relative flex-grow overflow-hidden" style={{ maxHeight: isExpanded ? 'none' : '250px' }}>
        <p ref={contentRef} className="text-gray-600 text-base font-serif leading-relaxed text-center">
          {text}
          <span className="inline-block ml-2 italic">- {name}</span>
        </p>
        {!isExpanded && shouldShowMore && (
          <div className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-t from-white to-transparent"></div>
        )}
      </div>
      {shouldShowMore && (
        <button 
          onClick={toggleExpand} 
          className="mt-2 text-blue-500 hover:text-blue-700 text-base font-medium transition-colors duration-200"
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
};

const ReviewCarousel: React.FC<ReviewCarouselProps> = ({ reviews }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
      <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-8 font-sans">What Our Clients Say</h2>
      <div className="px-4">
        <Slider {...settings}>
          {reviews.map((review) => (
            <ReviewCard key={review.id} {...review} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ReviewCarousel;