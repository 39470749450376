
  "use client";
  import React from 'react';
  import { Link } from 'react-router-dom';
  import '../css/index.css'; 
  import { CustomNavbar } from '../components/navbar';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faInstagram, faFacebookF, } from '@fortawesome/free-brands-svg-icons';
  import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
  import ReviewCarousel from '../components/ReviewCarousel';

  const reviews = [
  {
    id: 1,
    text: "Love this place and so happy I found my new lash tech! Pearl is super talented, gentle, and thoughtful. She talked to me before lashing about the exact style and length I wanted, and gave me advice which was great!",
    image: require('./review1.png'),
    rating: 5,
    name: "Anonymous",  
  },
  {
    id: 2,
    text: "Pearl is so knowledgeable and attentive. These are the most comfortable lashes and experience I have ever had. They also lasted forever 4 weeks+ and they still looked amazing. The 2nd time has me obsessed. She really runs you through the whole process and her application was magic and absolutely pain-free.",
    image: require('./review2.png'),
    rating: 5,
    name: "Anonymous",  
  },
];
  
  const Home = () => {
    React.useEffect(() => {
      // Add custom scrollbar styles
      const style = document.createElement('style');
      style.textContent = `
        /* WebKit browsers (Chrome, Safari) and Blink-based browsers (including Google Chrome) */
        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background-color: lightgray;
            border-radius: 10px;
            border: 2px solid transparent;
        }

        /* Firefox */
        * {
            scrollbar-width: thin;
            scrollbar-color: lightgray transparent;
        }

        /* Ensure the scrollbar is visible */
        html {
            overflow-y: scroll;
        }

        /* For Internet Explorer and Edge */
        body {
            -ms-overflow-style: scrollbar;
        }
      `;
      document.head.appendChild(style);

      // Clean up function
      return () => {
        document.head.removeChild(style);
      };
    }, []);

    return (
    <>
    {/* Landing Home page */}
    <CustomNavbar />

      {/* About section */}
      <div className="home-container min-h-screen pt-16 lg:pt-20">
      <section id="about" className="w-full flex flex-col items-center bg-white py-20">
        <div className="relative flex flex-col lg:flex-row items-center justify-between w-full max-w-7xl px-4 lg:px-8">
          <div className="w-full lg:w-3/5 flex flex-col items-center lg:items-start text-center lg:text-left mb-12 lg:mb-0">
            <h2 className="text-5xl lg:text-6xl font-extrabold text-gray-800 mb-6 animate-fade-in-down leading-tight">
              <span className="block text-slate-800">Welcome <i className="text-slate-800">to</i></span>
              <span className="block text-slate-800">Pear Lashed Studio</span>
            </h2>
            <p className="text-xl lg:text-2xl text-gray-700 mb-8 animate-fade-in-up max-w-2xl leading-relaxed">
              My goal is to create a unique experience and lash set customized for each client. With over 3 years of industry expertise, I am committed to enhancing your confidence through unique custom designs.
            </p>
            <p className="text-xl lg:text-2xl text-gray-700 mb-10 animate-fade-in-up font-light italic">
              Transforming lash aspirations into reality with precision, passion, and creativity
            </p>
            <div className="flex flex-wrap justify-center lg:justify-start gap-6">
              <Link 
              to="#contact" 
              className="contact-us inline-block px-10 py-5 bg-black text-white font-bold rounded-full hover:bg-gray-900 shadow-lg text-lg tracking-wide cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                const contactSection = document.getElementById('contact');
                if (contactSection) {
                  contactSection.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              CONTACT US
            </Link>
              <div className="flex items-center">
                <span className="inline-block w-8 h-8 bg-gray-900 rounded-full mr-3 animate-bounce">
                  <svg className="w-5 h-5 text-white mx-auto mt-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </span>
                <span className="text-lg text-gray-700 font-medium">Luxury Experience</span>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/5 flex justify-center items-center">
            <div className="relative">
              <img 
                src={require('./lashedbypearl.png')} 
                alt="Pear Lashed" 
                className="w-full max-w-lg rounded-lg shadow-2xl transform hover:rotate-2 transition-all duration-300 animate-float"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Welcome section */}
      
      <section id="home" className="w-full min-h-screen flex flex-col items-center justify-center bg-white relative overflow-hidden">
        <div className="absolute inset-0 opacity-10 lg:opacity-5"></div>
        <div className="relative z-10 flex flex-col lg:flex-row items-center justify-between w-full max-w-7xl px-4 lg:px-8 mt-20 lg:mt-0 mb-16 lg:mb-0">
          <div className="w-full lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0 transform transition-all duration-500 hover:scale-102">
            <h1 className="text-5xl sm:text-6xl lg:text-7xl font-extrabold mb-4 animate-fade-in-down font-sans leading-tight tracking-tighter">
              <span className="block bg-clip-text bg-slate-800">Lashed</span>
              <span className="block bg-clip-text bg-slate-800">by Pearl</span>
            </h1>
            <p className="text-xl sm:text-2xl lg:text-3xl text-gray-700 mb-8 animate-fade-in-up font-light">
              <span className="block font-medium tracking-wide uppercase">Custom Designed</span>
              <span className="block italic font-serif">Lash Extensions</span>
            </p>
            <a
              href="https://pearlashed.as.me/schedule.php"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-8 bg-black sm:px-10 py-4 sm:py-5 text-white font-bold rounded-full transform hover:scale-105 transition-all duration-300 shadow-lg text-base sm:text-lg tracking-wide animate-pulse"
            >
              Book an Appointment
            </a>
          </div>
          <div className="w-full lg:w-1/2 flex justify-center items-center">
          <div className="relative">
            <img
              src={require('./welcome.png')}
              alt="Pear Lashed"
              className="w-full max-w-sm lg:max-w-md rounded-lg shadow-2xl transform hover:rotate-2 transition-all duration-300 animate-float"
            />
            <div className="absolute inset-0 rounded-lg"></div>
          </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 bg-black text-white py-3 sm:py-4 text-center animate-slide-up">
          <p className="text-sm sm:text-base lg:text-lg font-medium tracking-wider uppercase">San Diego, CA | OPEN: Wed-Sat 9AM-7PM | Elevate Your Natural Beauty</p>
        </div>
      </section>
      
      {/* Top Services */}
      {/* bg-gradient-to-r from-rose-50 to-teal-50 */}
      <section id="services" className="top-services-section text-center py-20 bg-white">
        <h2 className="text-5xl font-extrabold mb-16 text-gray-800 animate-fade-in-down">
          <span className="block font-serif">Our Signature</span>
          <span className="block text-transparent bg-clip-text bg-slate-800 font-sans">Services</span>
        </h2>
        <div className="services-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 justify-items-center max-w-7xl mx-auto px-4">
          {[
            { name: "Classic Natural Set", image: "eye1.png", description: "Enhance your natural beauty with our delicate and precise classic lash extensions." },
            { name: "Volume Set", image: "eye2.png", description: "Achieve a dramatic and glamorous look with our luxurious volume lash extensions." },
            { name: "Hybrid Full Set", image: "eye3.png", description: "Experience the perfect blend of classic and volume for a customized, stunning lash look." }
          ].map((service, index) => (
            <div key={index} className="service-card group flex flex-col items-center rounded-3xl shadow-xl overflow-hidden transform transition-all duration-500 hover:scale-105 bg-white h-full">
              <div className="relative w-full h-72 overflow-hidden">
                <img 
                  src={require(`./${service.image}`)} 
                  alt={service.name} 
                  className="w-full h-full object-cover transition-all duration-500 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-50 transition-opacity duration-500"></div>
              </div>
              <div className="service-description flex-grow flex flex-col justify-between text-gray-800 text-center w-full p-8">
                <div>
                  <h3 className="font-bold text-2xl mb-4 text-transparent bg-clip-text bg-black font-serif">{service.name}</h3>
                  <p className="text-base text-gray-600 mb-6 leading-relaxed font-sans">{service.description}</p>
                </div>
                <div className="flex items-center justify-center mt-auto">
                  <span className="inline-block w-8 h-8 bg-black rounded-full mr-3">
                    <svg className="w-5 h-5 text-white mx-auto mt-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </span>
                  <span className="text-lg text-gray-700 font-medium">Premium Quality</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Policy */}
      <section id="policy" className="flex flex-col justify-center items-center bg-white gap-8 py-16 px-4 sm:px-6 lg:px-8 min-h-screen">
        <div className="w-full max-w-3xl space-y-8 lg:space-y-12 text-center">
          <h2 className="flex justify-center text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-6 text-gray-800 animate-fade-in-down font-serif">
            Booking <br className="hidden sm:inline" /><span className="text-black italic">POLICY</span>
          </h2>
          <div className="space-y-6 sm:space-y-8 lg:space-y-10">
            {[
              { title: "DEPOSIT/PAYMENT", content: "A $20 deposit is required to secure your booking. This is deducted from your final payment. It is non-refundable and non-transferable in the event of a cancellation. <span className='underline'>The remaining balance will be due in cash.</span>" },
              { title: "LATE POLICY", content: "If you are running late, please inform us in advance. You have a 10-minute grace period. If you are 15 minutes late, you'll be charged an additional $10 late fee. If you are more than 20 minutes late your appointment will be cancelled." },
              { title: "CANCELLATION", content: "Cancellation should be 48 hours or more, prior to your appointment. Failing to do so will be classed as a no-show which will require a $30 security deposit before booking your next appointment." }
            ].map((item, index) => (
              <div key={index} className="policy-item flex flex-col items-center animate-fade-in-up" style={{animationDelay: `${index * 0.2}s`}}>
                <h3 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4 border-b-2 border-black py-2 w-full lg:w-2/3 text-slate-800 font-sans">{item.title}</h3>
                <p className="text-gray-7000 leading-relaxed font-light text-base sm:text-lg" dangerouslySetInnerHTML={{__html: item.content}}></p>
              </div>
            ))}
          </div>
          <div className="policy-item flex flex-col items-center animate-fade-in-up" style={{animationDelay: '0.6s'}}>
            <a href="https://pearlashed.as.me/schedule.php?owner=32722856" target="_blank" rel="noopener noreferrer" className="mt-8 sm:mt-10 px-8 sm:px-12 py-4 sm:py-5 bg-black text-white font-bold rounded-full shadow-lg hover:bg-gray-900 transition-all duration-300 transform hover:scale-105 text-lg sm:text-xl tracking-wide">
              BOOK NOW
            </a>
          </div>
        </div>
      </section>
    
      {/* Prepping for Appointment */}
      <section id="preparation" className="flex flex-col items-center bg-gradient-to-b from-black to-gray-900 text-black py-16 sm:py-20 md:py-24 lg:py-28 px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl sm:text-5xl md:text-6xl font-playfair font-semibold mb-12 sm:mb-16 md:mb-20 text-center animate-fade-in-down text-white">
          Prepping for <br className="sm:hidden" /><span className="italic text-white font-light">YOUR APPOINTMENT</span>
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-10 md:gap-12 w-full max-w-7xl text-white">
          {[
            { number: "01", title: "Avoid Caffeine", description: "Reduce jitters and promote relaxation during your appointment." },
            { number: "02", title: "Remove Makeup", description: "Clean lashes ensure better retention. Please arrive with clean lashes for optimal results." },
            { number: "03", title: "Arrive on Time", description: "Punctuality is key for the perfect set. Late arrivals may result in appointment cancellation." }
          ].map((item, index) => (
            <div key={index} className="flex flex-col items-center text-center p-6 sm:p-8 bg-gray-800 rounded-2xl shadow-xl transform hover:scale-105 transition-all duration-300 animate-fade-in-up" style={{animationDelay: `${index * 0.2}s`}}>
              <div className="w-14 h-14 sm:w-16 sm:h-16 md:w-20 md:h-20 bg-white rounded-full flex items-center justify-center mb-4 sm:mb-6 transition-all duration-300 hover:bg-white">
                <span className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">{item.number}</span>
              </div>
              <h3 className="text-xl sm:text-2xl md:text-3xl font-semibold mb-3 sm:mb-4">{item.title}</h3>
              <p className="text-gray-300 text-sm sm:text-base md:text-lg leading-relaxed">{item.description}</p>
              <div className="mt-4 sm:mt-6">
                <svg className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 text-white mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </div>
            </div>
          ))}
        </div>
        <p className="mt-10 sm:mt-12 md:mt-16 text-lg sm:text-xl md:text-2xl font-light text-center max-w-3xl animate-fade-in-up text-white" style={{animationDelay: '0.6s'}}>
          Remember, <strong className="font-semibold text-white">arriving 20 minutes late</strong> may result in the cancellation of your appointment. Let's make your lash experience perfect together!
        </p>
      </section>

      {/* Reviews and Testimonials */}
      <section id="testimonials" className="min-h-screen flex flex-col justify-center items-center bg-white to-white py-16 sm:py-20 md:py-24 lg:py-28 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto w-full">
          <h2 className="text-4xl sm:text-5xl md:text-6xl font-playfair font-semibold mb-12 sm:mb-16 md:mb-20 text-center animate-fade-in-down">
            Client <span className="italic text-black font-light">Experiences</span>
          </h2>
          <div className="relative overflow-hidden">
            <ReviewCarousel reviews={reviews} />
          </div>
        </div>
      </section>

      {/* Contact Us section */}
      <section id="contact" className="min-h-screen bg-white py-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-5xl sm:text-6xl md:text-7xl font-playfair font-medium text-center mb-16 animate-fade-in-down text-black">
            Contact <span className="font-playfair italic text-black">Us</span>
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
            <div className="space-y-6 animate-fade-in-up" style={{animationDelay: '0.2s'}}>
              <a href="tel:+14087058914" className="flex items-center bg-white border-2 border-black rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg hover:bg-gray-100">
                <FontAwesomeIcon icon={faPhone} className="bg-black text-white text-2xl p-4" />
                <p className="text-lg font-medium pl-4 text-black">+1(408)-705-8914</p>
              </a>
              <a href="https://www.instagram.com/pear.lashed/" target="_blank" rel="noopener noreferrer" className="flex items-center bg-white border-2 border-black rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg hover:bg-gray-100">
                <FontAwesomeIcon icon={faInstagram} className="bg-black text-white text-2xl p-4" />
                <p className="text-lg font-medium pl-4 text-black">@PEAR.LASHED</p>
              </a>
            </div>
            <div className="space-y-6 animate-fade-in-up" style={{animationDelay: '0.4s'}}>
              <div className="flex items-center bg-white border-2 border-black rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg hover:bg-gray-100">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="bg-black text-white text-2xl p-4" />
                <p className="text-lg font-medium pl-4 text-black">San Diego, CA</p>
              </div>
            </div>
          
            <div className="flex flex-col justify-center items-center md:col-span-2 lg:col-span-1 animate-fade-in-up" style={{animationDelay: '0.6s'}}>
              <h3 className="text-3xl sm:text-4xl font-playfair font-medium mb-6 text-black">
                Follow <span className="font-playfair italic text-black">Us</span>
              </h3>
              <div className="flex space-x-6">
                <a href="https://www.instagram.com/pear.lashed/" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 hover:scale-110">
                  <FontAwesomeIcon icon={faInstagram} className="bg-black text-white text-2xl rounded-full p-3 w-12 h-12" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61562842288035&paipv=0&eav=AfbttH0FXbVnN0sXPNVzTCcLtzaS1tmkIvieLEL2BNWl6F7eyzmHNuDT3G1Q4zW8RZ4" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 hover:scale-110">
                  <FontAwesomeIcon icon={faFacebookF} className="bg-black text-white text-2xl rounded-full p-3 w-12 h-12" />
                </a>
                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=pear.lashedd@gmail.com" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 hover:scale-110">
                  <FontAwesomeIcon icon={faEnvelope} className="bg-black text-white text-2xl rounded-full p-3 w-12 h-12" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-full w-full overflow-hidden">
          <div className="flex animate-slide">
            <img src={require('./contact1.png')} alt="Contact 1" className="w-1/5 max-w-full aspect-square object-cover grayscale" />
            <img src={require('./contact2.png')} alt="Contact 2" className="w-1/5 max-w-full aspect-square object-cover grayscale" />
            <img src={require('./contact3.png')} alt="Contact 3" className="w-1/5 max-w-full aspect-square object-cover grayscale" />
            <img src={require('./contact4.png')} alt="Contact 4" className="w-1/5 max-w-full aspect-square object-cover grayscale" />
            <img src={require('./contact5.png')} alt="Contact 5" className="w-1/5 max-w-full aspect-square object-cover grayscale" />
          </div>
        </div>
      </section>
    
      <footer className="bg-black text-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            <div className="text-center md:text-left">
              <h3 className="text-2xl font-playfair mb-4">Pear Lashed Studio</h3>
              <p className="text-gray-400">&copy; 2024 All rights reserved</p>
              <p className="text-gray-400 mt-2 italic">Elevating beauty, one lash at a time.</p>
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-playfair mb-4">Quick Links</h3>
              <nav className="flex flex-col space-y-2">
                <a href="#home" className="text-gray-400 hover:text-white transition-colors duration-300">Home</a>
                <a href="#about" className="text-gray-400 hover:text-white transition-colors duration-300">About</a>
                <a href="#services" className="text-gray-400 hover:text-white transition-colors duration-300">Services</a>
                <a href="#preparation" className="text-gray-400 hover:text-white transition-colors duration-300">Preparation</a>
                <a href="#policy" className="text-gray-400 hover:text-white transition-colors duration-300">Policy</a>
                <a href="#contact" className="text-gray-400 hover:text-white transition-colors duration-300">Contact</a>
              </nav>
            </div>
            <div className="text-center md:text-right">
              <h3 className="text-2xl font-playfair mb-4">Contact Us</h3>
              <p className="text-gray-400">San Diego, CA</p>
              <p className="text-gray-400">+1(408)-705-8914</p>
              <p className="text-gray-400">pear.lashedd@gmail.com</p>
            </div>
          </div>
          <div className="border-t border-gray-800 pt-8 text-center">
            <p className="text-sm text-gray-400">
              <button className="hover:text-white transition-colors duration-300">Privacy Policy</button>
              {' | '}
              <button className="hover:text-white transition-colors duration-300">Terms of Service</button>
            </p>
          </div>
        </div>
      </footer>
    </div>
  </>
  );
}

export default Home;
