import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

export function CustomNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFirstSection, setIsFirstSection] = useState(true);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleLinkClick = (to: string) => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const homeSection = document.getElementById('home');
      const homeSectionHeight = homeSection ? homeSection.offsetHeight : 0;

      if (currentScrollY <= homeSectionHeight) {
        setIsFirstSection(true);
        setIsVisible(true);
      } else {
        setIsFirstSection(false);
        if (currentScrollY > lastScrollY) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      }
      setLastScrollY(currentScrollY);
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [lastScrollY]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && !(event.target as Element).closest('.navbar-menu') && !(event.target as Element).closest('.hamburger-button')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  const menuItems = ['Home', 'About', 'Services', 'Preparation', 'Policy', 'Contact'];

  const isLargeScreen = windowWidth >= 1200;
  // const isMediumScreen = windowWidth >= 992 && windowWidth < 1200;
  // const isSmallScreen = windowWidth >= 768 && windowWidth < 992;
  // const isExtraSmallScreen = windowWidth < 768;

  const showHamburger = !isLargeScreen;

  return (
    <>
      <nav className={`bg-white shadow-lg w-full fixed top-0 left-0 z-50 transition-all duration-300 ${!isVisible && !isFirstSection ? '-translate-y-full' : 'translate-y-0'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-20">
            {/* Company Logo and Name */}
            <div className="flex-shrink-0 flex items-center">
              <RouterLink to="https://pearlashedstudio.com" className="flex items-center group">
                <img src="/pearlogo.ico" className="h-10 w-auto sm:h-12 transition-transform duration-300 group-hover:scale-110" alt="Pear Lashed Studio Logo" />
                <div className="ml-3 flex flex-col">
                  <span className="text-xl sm:text-2xl lg:text-3xl font-serif tracking-wide leading-none">
                    <span className="text-slate-800">Pear</span>
                    <span className="text-slate-800">Lashed</span>
                    <span className="text-slate-800">Studio</span>
                  </span>
                  <span className="text-xs sm:text-sm font-sans tracking-widest uppercase text-slate-600 transition-all duration-300 group-hover:text-slate-600">
                    Custom Lash Designs
                  </span>
                </div>
              </RouterLink>
            </div>

            {/* Desktop menu */}
            {isLargeScreen && (
              <div className="flex items-center space-x-4 lg:space-x-6">
                {menuItems.map((item) => (
                  <ScrollLink key={item} to={item.toLowerCase()} smooth={true} duration={500} className="text-gray-800 hover:text-slate-600 px-2 lg:px-3 py-2 rounded-md text-sm lg:text-base font-medium transition-colors duration-200 font-sans">
                    {item}
                  </ScrollLink>
                ))}
                <a href="https://pearlashed.as.me/schedule.php" target="_blank" rel="noopener noreferrer" className="hover:text-black text-white font-bold py-2 px-3 lg:px-6 rounded-full bg-black hover:bg-white border border-black outline outline-black transition-colors duration-300 text-sm lg:text-base font-sans">
                  Book Now
                </a>
              </div>
            )}

            {/* Hamburger menu button */}
            {showHamburger && (
              <div className="ml-auto">
                <button onClick={toggleMenu} className="hamburger-button text-gray-800 hover:text-slate-400 focus:outline-none">
                  {isOpen ? (
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  ) : (
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
      
      {/* Overlay */}
      {showHamburger && (
        <div 
          className={`fixed inset-0 bg-black transition-opacity duration-300 ease-in-out ${
            isOpen ? 'opacity-50 z-40' : 'opacity-0 pointer-events-none'
          }`}
          onClick={toggleMenu}
        ></div>
      )}
      
      {/* Mobile and Tablet menu */}
      {showHamburger && (
        <div className={`navbar-menu fixed inset-y-0 right-0 z-50 w-80 sm:w-96 bg-white shadow-xl transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out overflow-y-auto`}>
          <div className="h-full flex flex-col bg-white">
            <div className="px-6 py-8 bg-white">
              <div className="flex justify-end items-center mb-8">
                <button onClick={toggleMenu} className="text-gray-800 hover:text-slate-400 focus:outline-none">
                  <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              {menuItems.map((item, index) => (
                <div key={index} className="py-3">
                  <ScrollLink 
                    to={item.toLowerCase()} 
                    smooth={true} 
                    duration={500} 
                    className="block text-xl font-medium text-gray-800 hover:text-slate-600 transition-colors duration-200 text-left pl-4 font-sans"
                    onClick={() => handleLinkClick(item.toLowerCase())}
                  >
                    {item}
                  </ScrollLink>
                </div>
              ))}
            </div>
            <div className="bg-gray-100 mt-auto px-6 py-8">
              <a 
                href="https://pearlashed.as.me/schedule.php" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="block w-full text-center bg-black text-white font-bold py-4 px-6 rounded-full hover:bg-gray-800 transition-colors duration-300 text-lg font-sans"
                onClick={() => setIsOpen(false)}
              >
                Book Now
              </a>
              <div className="mt-8">
                <p className="text-base text-gray-600 leading-relaxed font-sans">Experience the best in lash extensions and beauty services. We're committed to enhancing your natural beauty.</p>
              </div>
              <div className="mt-6 flex justify-center space-x-6">
                <a href="https://www.facebook.com/profile.php?id=61562842288035&paipv=0&eav=AfbttH0FXbVnN0sXPNVzTCcLtzaS1tmkIvieLEL2BNWl6F7eyzmHNuDT3G1Q4zW8RZ4" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-600 transition-colors duration-200">
                  <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                  </svg>
                </a>
                <a href="https://www.instagram.com/pear.lashed/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-pink-500 transition-colors duration-200">
                  <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}